<template>
  <div class="content">
    <el-card class="box-card">
      <div class="Untreated_top" slot="header">
        <span class="top_title">{{$t('警告码管理')}}</span>
        <div>
          <el-button type="primary" @click='dialogForm' v-if="user_role['warning_code/add']" size="small">
            {{$t('新增警告码')}}</el-button>
          <el-button type="primary" size="small" @click="getUserList(1,1)">{{ $t('导出EXCEL') }}</el-button>
        </div>
      </div>
      <div style="margin:15px 0; width: 250px;">
        <el-input :placeholder="$t('请输入警告码')" v-model="name" class="input-with-select">
          <el-button slot="append" icon="el-icon-search" @click="getUserList(1)"></el-button>
        </el-input>
      </div>
      <el-table :data="UntreatedData" height="500" style="width: 100%" border>
        <el-table-column :label="$t('ID')" prop="id" fixed="left" width="100" align="center" header-align="left">
        </el-table-column>
        <el-table-column :label="$t('警告码')" prop="warning_code">
        </el-table-column>
        <el-table-column :label="$t('警告信息')" prop="warning_info">
        </el-table-column>
        <el-table-column :label="$t('规定工时')">
          <template slot-scope="scope">
            <span>{{`${scope.row.working_hours}${$t('小时')}`}}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('修改日期')" prop="updated_at">
        </el-table-column>
        <el-table-column :label="$t('录入时间')" prop="created_at">
        </el-table-column>
        <el-table-column fixed="right" :label="$t('操作')" align="center" header-align="left" width="150">
          <template slot-scope="scope">
            <!-- <el-button type="text" size="small" @click="getDetail(scope)">查看详情</el-button> -->
            <el-button type="text" @click='dialogForm(scope,true)' v-if="user_role['warning_code/edit']" size="small">
              {{$t('修改')}}</el-button>
            <el-button type="text" @click="delRole(scope)" v-if="user_role['warning_code/delete']" size="small">
              {{$t('删除')}}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="Page.page"
        :page-size="Page.pagesize" layout="total, prev, pager, next, jumper" :total="Page.total"
        style="text-align: center;">
      </el-pagination>
    </el-card>
    <el-dialog :title="$t('新增警告码')" :visible.sync="addAdmin" width="80%">
      <el-row :gutter="20">
        <el-form :model="addform" label-position="left" label-width="100px" ref="addform">
          <el-col :span="12">
            <el-form-item :label="$t('警告码')" required prop="warning_code"
              :rules="[{ required: true, message: $t('不能为空')}]">
              <el-input v-model="addform.warning_code" clearable></el-input>
            </el-form-item>
          </el-col><!-- /警告码 -->
          <el-col :span="12">
            <el-form-item :label="$t('警告信息')" required prop="warning_info"
              :rules="[{ required: true, message: $t('不能为空')}]">
              <el-input v-model="addform.warning_info" clearable></el-input>
            </el-form-item>
          </el-col><!-- /警告信息 -->
          <el-col :span="12">
            <el-form-item :label="$t('规定工时')" required prop="working_hours"
              :rules="[{ required: true, message: $t('不能为空')}]">
              <el-input v-model="addform.working_hours" type="number" clearable></el-input>
            </el-form-item>
          </el-col><!-- /工时 -->
        </el-form>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addAdmin = false">{{$t('取消')}}</el-button>
        <el-button type="primary" @click="addMin">{{$t('确定')}}</el-button>
      </div>
    </el-dialog>
    <!--  -->
    <el-dialog :title="$t('修改警告码')" :visible.sync="editAdmin" width="80%">
      <el-row :gutter="20">
        <el-form :model="editform" label-position="left" label-width="100px" ref="editform">
          <el-col :span="12">
            <el-form-item :label="$t('警告码')" required prop="warning_code"
              :rules="[{ required: true, message: $t('不能为空')}]">
              <el-input v-model="editform.warning_code" clearable></el-input>
            </el-form-item>
          </el-col><!-- /警告码 -->
          <el-col :span="12">
            <el-form-item :label="$t('警告信息')" required prop="warning_info"
              :rules="[{ required: true, message: $t('不能为空')}]">
              <el-input v-model="editform.warning_info" clearable></el-input>
            </el-form-item>
          </el-col><!-- /警告信息 -->
          <el-col :span="12">
            <el-form-item :label="$t('规定工时')" required prop="working_hours"
              :rules="[{ required: true, message: $t('不能为空')}]">
              <el-input v-model="editform.working_hours" type="number" clearable></el-input>
            </el-form-item>
          </el-col><!-- /工时 -->
        </el-form>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editAdmin = false">{{$t('取消')}}</el-button>
        <el-button type="primary" @click="editMin">{{$t('确定')}}</el-button>
      </div>
    </el-dialog>
    <!-- 无详情 -->
    <el-dialog title="产品详情" :visible.sync="Detail" width="80%">
      <el-row :gutter="20">
        <el-form v-model="DetailForm" label-position="left" label-width="100px" ref="DetailForm">
          <el-col :span="12">
            <el-form-item label="客户ID" prop="oc_id">
              <el-input v-model="DetailForm.oc_id" disabled></el-input>
            </el-form-item>
          </el-col><!-- /客户ID -->
          <el-col :span="12">
            <el-form-item label="客户名称" prop="kehu_name">
              <el-input v-model="DetailForm.kehu_name" disabled></el-input>
            </el-form-item>
          </el-col><!-- /客户名称 -->
          <el-col :span="12">
            <el-form-item label="产品ID" prop="equipment_id">
              <el-input v-model="DetailForm.equipment_id" disabled></el-input>
            </el-form-item>
          </el-col><!-- /产品ID -->
          <el-col :span="12">
            <el-form-item label="产品名称" prop="equipment_name">
              <el-input v-model="DetailForm.equipment_name" disabled></el-input>
            </el-form-item>
          </el-col><!-- /产品名称 -->
          <el-col :span="12">
            <el-form-item label="产品类型id" prop="em_id">
              <el-input v-model="DetailForm.em_id" disabled></el-input>
            </el-form-item>
          </el-col><!-- /产品类型id -->
          <el-col :span="12">
            <el-form-item label="质保到期时间" prop="warranty_end_time">
              <el-input v-model="DetailForm.warranty_end_time" disabled></el-input>
            </el-form-item>
          </el-col><!-- /质保到期时间 -->
          <el-col :span="12">
            <el-form-item label="产品型号" prop="model_name">
              <el-input v-model="DetailForm.model_name" disabled></el-input>
            </el-form-item>
          </el-col><!-- /产品型号 -->
          <el-col :span="12">
            <el-form-item label="产品功率" prop="power">
              <el-input v-model="DetailForm.power" disabled></el-input>
            </el-form-item>
          </el-col><!-- /产品功率 -->
          <el-col :span="12">
            <el-form-item label="区域id" prop="or_id">
              <el-input v-model="DetailForm.or_id" disabled></el-input>
            </el-form-item>
          </el-col><!-- /区域id -->
          <el-col :span="12">
            <el-form-item label="区域名称" prop="rname">
              <el-input v-model="DetailForm.rname" disabled></el-input>
            </el-form-item>
          </el-col><!-- /区域名称 -->
          <el-col :span="24">
            <el-form-item label="备注" prop="remarks">
              <el-input v-model="DetailForm.remarks" disabled></el-input>
            </el-form-item>
          </el-col><!-- /备注 -->
        </el-form>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="Detail= false">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: 'WarningCode',
    data() {
      return {
        addAdmin: false,
        editAdmin: false,
        UntreatedData: [],
        name: '',
        addform: {
          warning_code: '',
          warning_info: '',
          working_hours: ''
        },
        editform: {
          id: '',
          warning_code: '',
          warning_info: '',
          working_hours: ''
        },
        DetailForm: {
          model_name: '',
          power: '',
          production_date: ''
        },
        Page: {
          pagesize: 10,
          page: 1,
          maxpage: 1,
          total: 2
        },
        isModify: false,
        Detail: false,
        user_role: {}
      }
    },
    created() {
      this.getUserList()
      this.user_role = JSON.parse(window.localStorage.getItem('user_role'))
    },
    mounted() {

    },
    methods: {
      // 打开表单
      dialogForm(item, isModify) {
        this.isModify = isModify
        if (isModify) {
          // console.log(item)
          this.editform = this.$common.forData2(item.row,this.editform)
          this.editAdmin = true
        } else {
          this.addAdmin = true
        }
      },
      // 获取客户区域列表
      getUserList(page, report) {
        const params = {
          page: page || this.Page.page,
          name: this.name
        }
        if (report === 1) {
          params.is_report = report
          this.$common.postExcelFile(params, 'warning_code/lists')
          return
        }
        this.$axios.post('warning_code/lists', params).then((res) => {
          const Data = res.data
          // console.log(Data)
          if (Data.code === 0) {
            this.UntreatedData = Data.data.data
            this.Page = {
              pagesize: Data.data.pagesize,
              page: Data.data.page,
              maxpage: Data.data.maxpage,
              total: Data.data.total
            }
          }
        }).catch((error) => console.log(error))
      },
      // 添加
      addMin() {
        const params = this.$common.forData(this.addform)
        this.$axios.post('warning_code/add', params).then((res) => {
          if (res.data.code === 0) {
            this.getUserList()
            this.addAdmin = false
            var that = this
            // 清空表单
            this.$common.resetField('addform', that)
            this.$message({
              type: 'success',
              message: res.data.message
            })
          } else {
            this.$message({
              type: 'warning',
              message: res.data.message
            })
            // console.log(res)
          }
        }).catch((error) => console.log(error))
      },
      // 修改
      editMin() {
        const params = this.$common.forData(this.editform)
        this.$axios.post('warning_code/edit', params).then((res) => {
          // console.log(res)
          if (res.data.code === 0) {
            this.getUserList()
            this.editAdmin = false
            var that = this
            this.$common.resetField('editform', that)
            this.$message({
              type: 'success',
              message: res.data.message
            })
          } else {
            this.$message({
              type: 'warning',
              message: res.data.message
            })
            // console.log(res)
          }
        }).catch((error) => console.log(error))
      },
      // 删除
      delRole(scope) {
        // console.log(scope)
        let that = this
        this.$MessageBox.confirm(that.$t('此操作将永久删除, 是否继续?'), that.$t('提示'), {
          confirmButtonText: that.$t('确定'),
          cancelButtonText: that.$t('取消'),
          type: 'warning'
        }).then(() => {
          this.$axios.post('warning_code/delete', {
            id: scope.row.id
          }).then((res) => {
            // console.log(res)
            if (res.data.code === 0) {
              this.getUserList()
              this.$message({
                type: 'success',
                message: that.$t('删除成功!')
              })
            } else this.$message.warning(res.data.message)
          }).catch((error) => this.$message.error(error))
        }).catch(() => {
          this.$message({
            type: 'info',
            message: that.$t('已取消删除')
          })
        })
      },
      // 获取详情
      getDetail(scope) {
        // console.log(scope)
        this.DetailForm = this.$common.forData2(item.row, this.DetailForm)
        this.Detail = true
      },
      // pageSize 改变时会触发
      handleSizeChange(val) {
        // console.log(`每页 ${val} 条`)
      },
      // currentPage 改变时会触发
      handleCurrentChange(val) {
        // console.log(`当前页: ${val}`)
        this.getUserList(val)
      }
    }
  }
</script>

<style scoped>
  .Untreated_top {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .top_title {
    color: #ff9252;
  }

  ::v-deep .el-select,
  ::v-deep .el-date-editor {
    width: 100% !important;
  }

  .el-input.is-disabled::v-deep .el-input__inner {
    color: #575757;
  }
</style>
